@import "base";

.label, label {
  color: $color-neutral-6;
  display: block;
}

label.light {
  color: $color-neutral-a;
}

label.capitalize {
  text-transform: capitalize;
}

.rt-noData label.light {
  color: $color-neutral-9;
}

.key__value--row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.key__value--row label {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.key__value--row label:first-child {
  flex-grow: .8;
}
label.validation-error {
  display: block;
}
.error label, label.attention {
  color: $color-attention-orange;
}

label.error {
  color: $color-error-red;
}

.key__value--row label.value { color: black; }
.key__value--row + .key__value--row { margin-top: 1.4rem; }
h1 + .key__value--list { margin-top: 4rem; }
h2 + .key__value--list { margin-top: 2rem; }
h1 + label.validation-error { margin-top: 4rem; }
.field + .validation-error {margin-top: 0.5rem;}
span + label { margin-top: 10px; }
