@import "base";

.ReactTable {
  border: none;
}
.table__wrapper {
  position: relative;
  max-width: calc(100vw - 22rem);
  overscroll-behavior: contain;
}

.modal__overlay .table__wrapper {
  max-width: 100vw;
}

.ReactTable .rt-table {
  padding-bottom: 5rem;
  overflow: visible;
}

.rt-thead {
  background: $color-neutral-7;
  padding: 0 2.6rem;
  z-index: 2;
  min-width: auto !important;
}

.rt-thead .rt-tr .rt-th.-cursor-pointer {
  text-align: left;
  color: white;
  padding: 1.1rem 1.3rem;

  transition: all .2s;

  &:not(:first-child):hover {
    background: $color-neutral-6;
  }

  &:nth-child(2):hover {
    box-shadow: inset 1px 0 0 0 $color-neutral-7;
  }

  &:before {
    content: "";
    position: absolute;
    top: 14px;
    left: 15px;
    width: 6px;
    height: 6px;
    border-bottom: 2px solid white;
    border-right: 2px solid white;
    transform: rotate(45deg);
    box-sizing: content-box;
    transition: all .2s;
    opacity: 0;
  }

  &.-sort-asc {
    padding-left: 3.4rem;
    box-shadow: none;
    &:before {
      opacity: 1;
    }
  }

  &.-sort-desc {
    padding-left: 3.4rem;
    box-shadow: none;
    &:before {
      opacity: 1;
      top: 18px;
      transform: rotate(225deg);
    }
  }
}

.rt-thead .rt-tr .rt-resizable-header-content { }

.ReactTable .rt-tbody {
  overflow: visible;
}

.ReactTable .rt-tbody .rt-tr-group {
 border-bottom: none;

  &:last-child {
    position: relative;
    border-bottom: none;
    &:after {
      content: '';
      position: absolute;
      width: 8px;
      height: 8px;
      background: $color-neutral-b;
      border-radius: 50%;
      right: 50%;
      margin-left: -4px;
      bottom: -3rem;
    }
  }
}
.rt-tbody .rt-tr-group {
  flex-direction: row;
}
.rt-tbody .rt-tr-group .rt-tr {
  padding: 0 2.6rem;
  border-bottom: 1px solid $color-neutral-b;
  min-height: 3.8rem;
}

.ReactTable.-multi .rt-tbody .rt-tr-group .rt-tr,
.ReactTable.-multi .rt-thead {
  padding: 0;
}

.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
  background: $color-neutral-f;
}
.ReactTable.-highlight.-selectable .rt-tbody .rt-tr { cursor: pointer; }

.ReactTable.-highlight.-selectable.-multi .rt-tbody .rt-tr .rt-td:first-child {
  position: relative;
  background: $color-neutral-f;
  padding-left: 3.8rem;
  max-width: 7rem !important;
  width: 7rem !important;

  input[type="checkbox"] {
    position: absolute;
    left: 0;
    top: 0;
    width: 7rem;
    height: 100%;
    z-index: 1;
    opacity: 0;
    cursor: pointer;
  }

  &:before {
    content: '';
    position: absolute;
    display: block;
    width: 20px;
    height: 20px;
    background: white;
    border: 1px solid $color-neutral-c;
    border-radius: 3px;
    top: 8px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  }
  &:after {
    content: '';
    position: absolute;
    display: block;
    top: 1.5rem;
    left: 4.4rem;
    width: .8rem;
    height: .4rem;
    border: .2rem solid white;
    border-top: none;
    border-right: none;
    transform: rotate(-45deg);
    box-shadow: none;
  }
}

.ReactTable.-highlight.-selectable.-multi .rt-thead .rt-tr .rt-th:first-child {

  > div:before {
    content: '';
    position: absolute;
    display: block;
    top: 1.5rem;
    left: 4.2rem;
    width: .8rem;
    height: .4rem;
    border: .2rem solid $color-neutral-b;
    border-top: none;
    border-right: none;
    transform: rotate(-45deg);
    box-shadow: none;
    opacity: .7;
  }

  > div:after {
    content: '';
    position: absolute;
    display: block;
    top: 1.5rem;
    left: 4.5rem;
    width: .4rem;
    height: .9rem;
    transform: rotate(-45deg);
    background: white;
  }

  input[type="checkbox"] {
    position: absolute;
    left: 0;
    top: 0;
    width: 7rem;
    height: 100%;
    z-index: 1;
    opacity: 0;
    cursor: pointer;
  }

  position: relative;
  background: $color-neutral-6;
  padding-left: 3.8rem;
  max-width: 7rem !important;
  width: 7rem !important;
  &:before {
    content: '';
    position: absolute;
    display: block;
    width: 20px;
    height: 20px;
    background: white;
    border: 1px solid white;
    border-radius: 3px;
    top: 8px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  }
  &:after {
    content: '';
    position: absolute;
    display: block;
    top: 1.5rem;
    left: 4.6rem;
    width: .8rem;
    height: .4rem;
    border: .2rem solid $color-neutral-b;
    border-top: none;
    border-right: none;
    transform: rotate(-45deg);
  }
}

.ReactTable.-highlight .rt-tbody .rt-tr.disabled {
  background: $color-neutral-e;
  pointer-events: none;
  .rt-td { color: $color-neutral-a; }
}

.ReactTable.-highlight.-multi .rt-tbody .rt-tr.disabled .rt-td:first-child {
  background: $color-neutral-e;
  border-right:  none;

  &:before {
    box-shadow: none;
    background: $color-neutral-f;
    border-color: $color-neutral-e;
  }
}

.ReactTable.-highlight.-selectable .rt-tbody .rt-tr:not(.selected):hover {
  background: $color-primary-f;
  border-bottom-color: $color-primary-a;
  border-top: 1px solid $color-primary-a;
  margin-top: -1px;
  min-height: 3.9rem;
}

.ReactTable.-highlight.-selectable.-multi .rt-tbody .rt-tr:not(.selected):hover .rt-td:first-child {
  background: $color-primary-e;
}

.ReactTable.-highlight.-selectable .rt-tbody .rt-tr.selected {
  background: $color-primary-e;
  border-top: 1px solid $color-primary-a;
  border-bottom-color: $color-primary-a;
  margin-top: -1px;
  min-height: 3.9rem;
}

.ReactTable.-highlight.-selectable.-multi .rt-tbody .rt-tr.selected .rt-td:first-child {
  background: $color-primary-d;

  &:before {
    background: $color-primary-6;
    border-color: $color-primary-5;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  }
}

.ReactTable.-highlight.-selectable .rt-thead.selected .rt-tr {
  .rt-th:first-child:before {
    background: $color-primary-6;
    border-color: $color-primary-6;
    box-shadow: none;
  }

  .rt-th:first-child:after {
    border-color: white;
  }

  .rt-th:first-child > div:before {
    border-color: white;
  }

  .rt-th:first-child > div:after {
     background: $color-primary-6;
   }
}

.ReactTable.-highlight:not(.-multi) .rt-tbody .rt-tr .rt-td:first-child,
.ReactTable.-highlight:not(.-multi) .rt-thead .rt-tr .rt-th:first-child {
  display: none;
}

.rt-tbody .rt-tr-group .rt-tr .rt-td {
  padding: 1.1rem 1.3rem;
  user-select: none;
  &.text-wrap { white-space: normal; }
}

.ReactTable .pagination-bottom {
  position: sticky;
  bottom: 0;
  background: white;
  margin-top: 1rem;
  box-shadow: 0 -2px 4px rgba(0,0,0,.2);
  transition: all .2s;

  &.un-stuck {
    box-shadow: none;
  }
}

.ReactTable .-pagination {
  padding: 1rem 4rem;
  justify-content: flex-start;
  border: none;
  box-shadow: none;
}

.modal__overlay .ReactTable .-pagination {
  padding: 1rem 4rem;
  justify-content: center;
  border: none;
  box-shadow: none;
}

.modal__overlay .ReactTable .-pagination {
  margin-bottom: 44px;
}

.modal__overlay .ReactTable .pagination-bottom {
  position: relative;
  bottom: initial;
  background: white;
  margin-top: 1rem;
  box-shadow: none;
  transition: all .2s;

  &.un-stuck {
    box-shadow: none;
  }
}

.ReactTable .-pagination .-center {
  margin: 0 3rem;
}

.ReactTable .-pagination .-previous, .ReactTable .-pagination .-next, .ReactTable .-pagination .-center {
  flex: 0;
}

.ReactTable .-pagination .-pageInfo {
  margin: 0;
}

.ReactTable .-pagination .-pageJump input {
  margin: 0 5px;
}

.ReactTable .rt-noData {
  position: relative;
  top: -4rem;
  left: auto;
  transform: initial;
  background: $color-neutral-f;
  text-align: center;
  border-top: 1px solid $color-neutral-d;
  border-bottom: 1px solid $color-neutral-d;
  color: #000;
  padding: 1.3rem 0;
}

.rt-thead > .rt-tr {
  overflow-x: hidden;
}

.ReactTable .rt-tbody {
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 4rem;
}

.ReactTable .syncscroll {
  min-width: auto !important;
}

.table {
  background: $color-neutral-b;
}

.tbody .tr + .tr { margin-top: 1px; }

.thead > .tr {
  display: flex;
  position: relative;
  width: 100%;
  flex-shrink: 0;
  justify-content: space-between;
  color: white;
  background: $color-neutral-7;
  padding: 0 4rem;
}

.card .tr {
  padding: 0 3rem;
}

.th, .td {
  padding: 1.1rem 1rem;
}

.tr {
  display: flex;
  position: relative;
  width: 100%;
  flex-shrink: 0;
  justify-content: space-between;
  padding: 0 4rem;
  background: white;
}

.tbody .tr:hover:not(.no-select) {
  background: $color-primary-f;
  box-shadow: 0 1px 0 0 $color-primary-a, 0 -1px 0 0 $color-primary-a, inset 1px 0 0 0 $color-primary-a, inset -1px 0 0 0 $color-primary-a;
  cursor: pointer;
}