
.Toastify__toast .status-icon {
  background: url("icons/status-general.svg") no-repeat;
  background-size: cover;
}

.Toastify__toast.success .status-icon {
  background-image: url("icons/status-success.svg");
}

.Toastify__toast.error .status-icon {
  background-image: url("icons/status-failed.svg");
}