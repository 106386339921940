@import "../../styles/base";

.section__loader {
  position: relative;
  height: 3.4rem;
  pointer-events: none;
  opacity: 1;
  text-align: center;
  width: 100%;
  margin: 8rem 0;

  span {
    position: absolute;
    display: block;
    width: 100%;
    top: 50px;
    text-align: center;
  }

  p {
    text-align: center;
  }

  &:after {
    content: '';
    position: absolute;
    left: 50%;
    margin-left: -15px;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 30px;
    height: 30px;
    border: 2px solid $color-neutral-b;
    border-top-color: $color-neutral-e;
    border-left-color: $color-neutral-e;
    border-radius: 17px;
    animation: rotate .9s linear infinite;
    box-sizing: content-box;
  }
  &.small {
    height: 18px;
  }
  &.small:after {
    width: 16px;
    height: 16px;
  }
}

section > .section__loader { margin-top: 4rem; }
.td > .section__loader { margin-top: 0; }

.table + .section__loader { margin-top: 10rem; }

@keyframes rotate {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}