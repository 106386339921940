@import "base";


.login__form {
  position: relative;
  width: 60rem;
  padding: 10rem;
  box-shadow: 0 10px 20px rgba(0,0,0,.2);
  height: 100vh;
}

.login__form .header__logo {
  width: 100%;
  height: auto;
}

.login__form {
  .header__logo + .ui.input { margin-top: 5rem; }
  .ui.input + .ui.input { margin-top: 1rem; }
  .button__primary { margin-top: 3rem; }
}

.login__page .login__background {
  width: calc(100% - 60rem);
  height: 100vh;
  position: absolute;
  right: 0;
  top: 0;
  background: $color-neutral-6;
}

.login__footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 2rem 10rem;
  background: $color-neutral-f;
  font-size: 10px;

  p {
    margin: 0;
    line-height: 1.6;
  }
}