@import "base";

.selection.dropdown {
  position: relative;
  min-width: 10rem;
  height: 3.4rem;
  color: black;
  border-radius: .3rem;
  border: .1rem solid $color-neutral-b;
  box-shadow: 0 1px 3px rgba(0,0,0,.2);
  outline: none;
  background: white;
  font-size: $font-size;
  cursor: pointer;

  transition: all .2s;

  &.disabled {
    background: $color-neutral-e !important;
    color: $color-neutral-a !important;
    box-shadow: inset 0 1px 3px transparent, 0 0 4px 2px transparent !important;
    cursor: default;
  }

  &:active {
    color: $color-neutral-7;
    box-shadow: 0 1px 3px transparent;
    background: $color-neutral-f;
  }

  .text {
    height: 3.4rem;
    line-height: 3.4rem;
    padding: 0 1.3rem;
  }

  .dropdown.icon {
    position: absolute;
    right: 1.4rem;
    top: 1rem;
    width: .9rem;
    height: .9rem;
    border: 2px solid $color-neutral-5;
    transform: rotate(45deg);

    border-top: none;
    border-left: none;

    transition: all .2s;
  }

  &.active .dropdown.icon {
    top: 1.4rem;
    transform: rotate(-135deg);
  }

  .menu {
    position: relative;
    display: block;
    visibility: hidden;
    overflow: hidden;
    max-height: 0;
    opacity: 0;
    background: white;
    border: 1px solid $color-neutral-b;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    padding: 1.1rem 0;
    border-radius: 3px;
    z-index: 2;

    transition: all .2s;
  }

  .menu.visible {
    display: block;
    overflow: auto;
    visibility: visible;
    max-height: 24rem;
    opacity: 1;
  }

  .item:not(.selected):hover {
    background: $color-primary-f;
    box-shadow: 0 -1px 0 0 $color-primary-a, 0 1px 0 0 $color-primary-a;
  }
  .item.selected {
    background: $color-primary-e;
    box-shadow: 0 -1px 0 0 $color-primary-a, 0 1px 0 0 $color-primary-a;
  }

  &.search {
    border: none;
    box-shadow: none;
    >.text {
      position: absolute;
      top: 0;
      &.filtered { display: none; }
    }
    .menu { top: 2px; }
  }
}

label + .selection.dropdown { margin-top: .5rem; }