@import "../../styles/base";

.app__menu {
  position: fixed;
  top: 5.6rem;
  left: 0;
  width: 22rem;
  height: calc(100vh - 5.6rem);
  background: $color-neutral-6;
}

.menu__trigger {
  position: relative;
  height: 56px;
  background: $color-neutral-4;

  &.cursor__pointer {
    cursor: pointer;
  }

  .button__label {
    position: absolute;
    display: block;
    background: $color-neutral-4;
    width: 100%;
    height: 56px;
    padding: 18px 12px 12px 64px;
    top: 0;
    left: 0;

    .label {
      position: relative;
      top: 2px;
      color: white;
      font-size: 14px;
    }

    .menu__icon {
      position: absolute;
      display: block;
      left: 23px;
      top: 27px;
      width: 24px;
      height: 2px;
      margin-bottom: 5px;
      background-color: $white;

      &:before, &:after {
        position: absolute;
        display: block;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: $white;
        content: "";
      }
      &:before {
        transform-origin: top right;
        transition: transform 0.3s, width 0.3s, top 0.3s;
        top: -7px;
      }
      &:after {
        transform-origin: bottom right;
        transition: transform 0.3s, width 0.3s, bottom 0.3s;
        bottom: -7px;
      }
    }

    .close__icon {
      position: absolute;
      display: block;
      left: 24px;
      top: 27px;
      width: 18px;
      height: 2px;
      margin-bottom: 3px;
      background-color: $white;
      &:before, &:after {
        position: absolute;
        display: block;
        left: -12px;
        width: 100%;
        height: 2px;
        background-color: $white;
        content: "";
      }

      &:before {
        top: -1px;
        transform: translateX(10px) translateY(4px) rotate(45deg);
      }
      &:after {
        bottom: -1px;
        transform: translateX(10px) translateY(-4px) rotate(-45deg);
      }
      &:before, &:after {
        width: 50%;
      }
    }
  }
}

.menu__items {
  margin-top: $s-size;
  padding: 1rem;
}

.menu__items.menu__items--common {
  position: absolute;
  width: 100%;
  bottom: 5px;
}

.menu__item {
  color: white;
  display: flex;
  text-decoration: none;
  line-height: 4.2rem;
  height: 4.2rem;
  padding-left: 1.2rem;
  border-radius: .5rem;
  align-items: center;

  &.is-active {
    background: $color-neutral-a;
  }

  &:hover:not(.is-active) {
    background: $color-neutral-4;
  }
}

.sub__menu {
  margin: .5rem 0 1rem 4rem;
}

.sub__menu .menu__item {
  border-radius: 3px;
  color: $color-neutral-b;
  line-height: 2.6rem;
  height: 2.6rem;

  &.is-active {
    color: white;
    background: $color-neutral-a;
    cursor: default;
  }

  &:hover:not(.is-active) {
    background: $color-neutral-5;
  }
}

.menu__item svg {
  fill: white;
  margin-right: 1.6rem;
}

.menu__item + .menu__item { margin-top: 5px; }