@import "base";

.DateRangePicker_picker {
  z-index: 3;
}

.SingleDatePicker_picker {
  z-index: 3;
}

.DateRangePickerInput_arrow_svg {
  fill: transparent;
  width: 1rem;
}

.DateInput {
  width: 22rem;
}

.SingleDatePicker + .SingleDatePicker, .SingleDatePicker + button  { margin-left: 10px; }
.field--date-picker { margin-top: 14px; }


input[type="text"] + .field--date-picker { margin-top: 14px; }

.DayPicker__withBorder {
  box-shadow: 0 2px 6px rgba(0,0,0,.05), 0 0 0 1px $color-neutral-c;
}

.CalendarMonthGrid__horizontal {
  left: 0;
}

.CalendarMonth {
  padding: 0 !important;
}

.CalendarMonth_table {
  position: relative;
  width: 274px;
  left: 22px;
}

.CalendarMonth_caption {
  font-size: $font-size;
  background: $color-neutral-e;
  margin-bottom: 10px;
  padding-bottom: 50px;
  width: 318px;
  color: black;
  line-height: 2.4rem;
  border-bottom: 1px solid $color-neutral-c;

  strong { font-weight: normal; }
}

.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
  background: $color-primary-c;
  border-color: $color-primary-a;
  color: black;
}

.CalendarDay__selected_span, .CalendarDay__selected_span:hover {
  background: $color-primary-e;
  border: 1px double $color-primary-a;
  color: black;
}

.CalendarDay__default:hover {
  background: $color-primary-f;
  border: 1px double $color-primary-a;
}

.CalendarDay__blocked_out_of_range, .CalendarDay__blocked_out_of_range:active, .CalendarDay__blocked_out_of_range:hover {
  background: white;
  border: 1px double $color-neutral-e;
}

.CalendarDay__hovered_span, .CalendarDay__hovered_span:hover {
  background: $color-primary-f;
  border: 1px double $color-primary-a;
  color: black;
}

.DayPicker_weekHeader {
  color: black;
}

.DayPicker_weekHeader_li {
  small {
    font-size: 100%;
  }
}

.DayPickerNavigation_button__default {
  @extend .button;
  position: absolute;
  width: 3.4rem;
  min-width: 3.4rem;

  svg { opacity: 0; }

  &:before {
    content: '';
    position: absolute;
    display: block;
    width: 6px;
    height: 6px;
    border: 2px solid $color-neutral-6;
    border-right-color: transparent;
    border-bottom-color: transparent;
    transform: rotate(-45deg);
    top: 11px;
    border-radius: 2px;
  }
}

.DayPickerNavigation_rightButton__horizontalDefault {
  &:before {
    transform: rotate(135deg);
    right: 13px;
  }
}

.DateRangePickerInput_clearDates {
  position: absolute;
  transform: initial;
  right: -54px;
  top: 0;
}

.DateRangePickerInput__showClearDates {
  position: relative;
  margin-top: 5px;
  padding-right: 0;
}

.DateInput_fang { display: none; }

.field--date-picker {
  label + .SingleDatePicker { margin-top: 5px; }
  .button.ui { top: 1px; }
}

.form-validation-field {
  .DateInput {
    width: 550px
  }

  .SingleDatePickerInput__withBorder {
  }
}