@import "base";

.status {
  display: flex;
  align-items: center;
  font-size: 0;
  .status-icon,
  .status-info {
    display: inline-block;
    vertical-align: middle;
  }
  .status-info {
    margin-left: 10px;
    line-height: 1;
    font-size: $font-size;
    label {
      display: block;
      margin-top: 0;
      line-height: 19px;
      color: $color-neutral-6;
    }

    label.attention { white-space: normal; }
  }


  .status-message,
  .status-date {
    white-space: normal;
    line-height: $font-size * 1.5;
  }
  .status-date {
    color: $color-neutral-6;
  }

  .status-message {
    label + label { margin-top: 0 ;}
    .failed-row { margin-top: 5px; }
  }
  .status-icon {
    display: inline-block;
    flex-shrink: 0;
  }
}

.status.attention, .status.USER_INPUT_REQUIRED , .status.warning, .status.RISK_IDENTIFIED {
  .status-icon {
    position: relative;
    width: 22px;
    height: 25px;
    svg {
      fill: $color-attention-orange;
      position: absolute;
      top: 0;
    }
  }
  .status-message { color: $color-attention-orange; }
}

.success .status, .status.success, .status.RECEIVED, .status.COMPLETE, .status.pass, .status {
  .status-icon, .status-icon.success {
    position: relative;
    width: 24px;
    height: 24px;
    overflow: hidden;
    box-sizing: content-box;
    svg {
      fill: $color-succes-green;
      position: absolute;
      top: 0;
    }
  }
  .status-message { color: $color-succes-green; }
}

.status.unset {
  .status-icon {
    position: relative;
    width: 0;
    height: 0;
    overflow: hidden;
    box-sizing: content-box;
  }
  .status-info {
    margin-left: 0;
  }
  .status-message { color: $color-neutral-a; }
}

.error .status, .status.failed, .status.ERROR_RECEIVED, .status.danger, .status.fail {
  .status-icon {
    position: relative;
    width: 24px;
    height: 24px;
    overflow: hidden;
    box-sizing: content-box;
    svg {
      fill: $color-error-red;
      position: absolute;
      top: 0;
    }
  }
  .status-message { color: $color-error-red; }
}

.status.ABORTED, .status.aborted, .status.review {
  .status-icon {
    position: relative;
    width: 24px;
    height: 24px;
    overflow: hidden;
    box-sizing: content-box;
    svg {
      fill: $color-attention-orange;
      position: absolute;
      top: 0;
    }
  }
  .status-message { color: $color-attention-orange; }
}

.general .status, .status.general {
  .status-icon {
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    overflow: hidden;
    svg {
      fill: $color-primary;
      position: absolute;
      top: 0;
    }
  }
  .status-message {
    color: $color-primary;
  }
}

.status.processing, .status.PENDING, .status.INITIAL, .status.active {
  line-height: 21px;
  .status-icon {
    svg {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      overflow: hidden;
      .circle {
        fill: $color-neutral-6;
      }
      .wave {
        fill: $color-neutral-b;
        animation: animation-processing 1.4s linear infinite;
      }
    }
  }
  .status-message {
    color: $color-neutral-6;
  }
}

@keyframes animation-processing {
  0% { transform: translate(0,0); }
  100% { transform: translate(-33px,0); }
}

.status.PAUSED, .status.paused, .status.PAUSING {
  .status-icon {
    position: relative;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid $color-neutral-6;
    overflow: hidden;
    box-sizing: content-box;
    z-index: 1;
    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 7px;
      background: $color-neutral-b;
    }
  }
  .status-message {
    color: $color-neutral-6;
  }
}

.status.no-match, .status.NO_RESULT {
  .status-icon {
    position: relative;
    width: 24px;
    height: 24px;
    box-sizing: content-box;
    svg {
      fill: $color-neutral-6;
      position: absolute;
      top: 0;
    }
  }
  .status-message {
    color: $color-neutral-6;
  }
}

.status {
  .status-icon {
    svg {
      &.position-relative {
        position: relative;
      }
    }
  }
}
