@import "base";

input[type="text"], input[type="password"], input[type="number"], textarea, .ReactTable .-pagination input {
  width: 100%;
  height: 3.4rem;
  line-height: 3.4rem;
  padding: 0 1.3rem;
  border: 1px solid $color-neutral-b;
  border-radius: 3px;
  box-shadow: inset 0 1px 3px rgba(0,0,0,.05), 0 0 4px 2px transparent;
  color: $color-neutral-4;
  background: linear-gradient($color-neutral-f, white 20px);
  outline: none;
  max-width: 55rem;
  font-size: $font-size;

  &:focus {
    border-color: $color-primary-4;
    box-shadow: inset 0 1px 3px transparent, 0 0 4px 2px $color-primary;
    background: white;
  }

  &::-webkit-input-placeholder {
    color: $color-neutral-b;
    font-size: $font-size;
    line-height: 3.4rem;
  }
}

textarea {
  line-height: $font-size * 1.5;
  padding: 4px 12px;
}

input[type="text"]:disabled, input[type="password"]:disabled, input[type="number"]:disabled, textarea:disabled {
  background: $color-neutral-e;
  color: $color-neutral-a;
  box-shadow: inset 0 1px 3px transparent, 0 0 4px 2px transparent;
}

input[type="text"].attention, input[type="password"].attention, textarea.attention,
input[type="text"].error, input[type="password"].error, textarea.error {
  border-color: $color-attention-orange;
  box-shadow: inset 0 1px 3px transparent, 0 0 1px 0px $color-attention-orange;
  &::selection { background: rgba( $color-attention-orange, .2 ); }
  &:focus { box-shadow: inset 0 1px 3px transparent, 0 0 4px 2px $color-attention-orange; }
}

.error {
  input[type="text"], input[type="password"], textarea {
    border-color: $color-attention-orange;
    box-shadow: inset 0 1px 3px transparent, 0 0 1px 0px $color-attention-orange;
    &::selection { background: rgba( $color-attention-orange, .2 ); }
    &:focus { box-shadow: inset 0 1px 3px transparent, 0 0 4px 2px $color-attention-orange; }
  }
}

.section__inline--elements input[type="text"] {
  width: 30rem
}

label + .ui.input, label + input[type="text"], label + .SingleDatePicker { margin-top: .5rem; }
h1 + .ui.form { margin-top: 4rem; }
.field + .field, .validation-error + .ui.form { margin-top: 1.4rem; }
.form-validation-field + .form-validation-field { margin-top: 1.4rem; }
