.react-tabs {

}

.react-tabs__tab-list {
  display: flex;
  flex-direction: row;
  list-style: none;
  padding: 0;
  margin: 4rem;
  align-content: space-between;
  border: 1px solid $color-neutral-c;
  border-radius: 3px;
}

.react-tabs__tab {
  width: 100%;
  text-align: center;
  line-height: 3.4rem;
  height: 3.4rem;
  background: white;
  box-shadow: 0 1px 3px rgba(0,0,0,.1);
  cursor: pointer;

  &:first-child { border-radius: 3px 0 0 3px; }
  &:last-child { border-radius: 0px 3px 3px 0; }

  &:focus {
    outline: none;
  }
}
.react-tabs__tab.react-tabs__tab--selected {
  background: $color-neutral-f;
  box-shadow: none;
  color: $color-neutral-7;
  cursor: default;
}

.react-tabs__tab.react-tabs__tab--disabled {
  box-shadow: none;
  background: $color-neutral-e;
  color: $color-neutral-a;
  border-color: $color-neutral-e;
  cursor: default;
}

.react-tabs__tab + .react-tabs__tab { border-left: 1px solid $color-neutral-c; }