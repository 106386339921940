

.section__full--width {
  width: 100%;
  padding: 0;
}

.section__full--width--padding {
  width: 100%;
  padding: 0 $l-size;
}

.section__inline--elements {
  display: flex;
  padding: $s-size $l-size;
  align-items: center;
  background: white;
  z-index: 1;
}

.section__columns {
  display: flex;
}

.section__column {
  position: relative;
}

.border-top {
  border-top: .1rem solid $color-neutral-c;
}

.single__column {
  max-width: 550px;
  margin: 0 auto;
}

.sticky__section {
  background: white;
  left: 0;
  &.sticky__section--footer { padding: 1rem 0 1rem !important; }
}
.is-sticky .sticky__section.sticky__section--footer { box-shadow: 0 -2px 4px rgba(0, 0, 0, .2)}

.section__inline--elements h3 {  line-height: 3.4rem; }
.section__inline--elements h3.pull-right { text-transform: lowercase; }

.single__column + .single__column, .single__column + .section__full--width, form + .single__column { margin-top: 4rem; }

