@import "base";

.radio {
  input { position: absolute; }
  label {
    line-height: 22px;
    padding-left: 3.4rem;
  }

  label:before {
    top: 0;
    left: 0;
    border-radius: 50%;
  }
  label:after {
    width: .6rem;
    height: .6rem;
    border: none;
    background: white;
    border-radius: 50%;
    top: .8rem;
    left: .8rem;
  }
}

.radio__group {
  .field + .field { margin-top: .6rem; }
  .form-validation-field + .form-validation-field { margin-top: 6px; }
}

.radio__group + .radio__group { margin-top: 14px; }
.field + .radio__group, .form-validation-field + .radio__group { margin-top: 1.4rem; }

label + .form-validation-field { margin-top: 1rem; }
