$white: #ffffff;

$color-encompass-blue: #43bdd8;
$color-encompass-blue-0: mix(black, $color-encompass-blue, 80%);
$color-encompass-blue-1: mix(black, $color-encompass-blue, 67%);
$color-encompass-blue-2: mix(black, $color-encompass-blue, 54%);
$color-encompass-blue-3: mix(black, $color-encompass-blue, 41%);
$color-encompass-blue-4: mix(black, $color-encompass-blue, 28%);
$color-encompass-blue-5: mix(black, $color-encompass-blue, 15%);
$color-encompass-blue-6: $color-encompass-blue;
$color-encompass-blue-7: mix(white, $color-encompass-blue, 14%);
$color-encompass-blue-8: mix(white, $color-encompass-blue, 29%);
$color-encompass-blue-9: mix(white, $color-encompass-blue, 43%);
$color-encompass-blue-a: mix(white, $color-encompass-blue, 56%);
$color-encompass-blue-b: mix(white, $color-encompass-blue, 67%);
$color-encompass-blue-c: mix(white, $color-encompass-blue, 76%);
$color-encompass-blue-d: mix(white, $color-encompass-blue, 84%);
$color-encompass-blue-e: mix(white, $color-encompass-blue, 91%);
$color-encompass-blue-f: mix(white, $color-encompass-blue, 96%);

$color-base-neutral: #2e4561;
$color-neutral-0: mix(black, $color-base-neutral, 80%);
$color-neutral-1: mix(black, $color-base-neutral, 67%);
$color-neutral-2: mix(black, $color-base-neutral, 54%);
$color-neutral-3: mix(black, $color-base-neutral, 41%);
$color-neutral-4: mix(black, $color-base-neutral, 28%);
$color-neutral-5: mix(black, $color-base-neutral, 15%);
$color-neutral-6: $color-base-neutral;
$color-neutral-7: mix(white, $color-base-neutral, 14%);
$color-neutral-8: mix(white, $color-base-neutral, 29%);
$color-neutral-9: mix(white, $color-base-neutral, 43%);
$color-neutral-a: mix(white, $color-base-neutral, 56%);
$color-neutral-b: mix(white, $color-base-neutral, 67%);
$color-neutral-c: mix(white, $color-base-neutral, 76%);
$color-neutral-d: mix(white, $color-base-neutral, 84%);
$color-neutral-e: mix(white, $color-base-neutral, 91%);
$color-neutral-f: mix(white, $color-base-neutral, 96%);

$color-primary: $color-encompass-blue;
$color-primary-0: $color-encompass-blue-0;
$color-primary-1: $color-encompass-blue-1;
$color-primary-2: $color-encompass-blue-2;
$color-primary-3: $color-encompass-blue-3;
$color-primary-4: $color-encompass-blue-4;
$color-primary-5: $color-encompass-blue-5;
$color-primary-6: $color-encompass-blue-6;
$color-primary-7: $color-encompass-blue-7;
$color-primary-8: $color-encompass-blue-8;
$color-primary-9: $color-encompass-blue-9;
$color-primary-a: $color-encompass-blue-a;
$color-primary-b: $color-encompass-blue-b;
$color-primary-c: $color-encompass-blue-c;
$color-primary-d: $color-encompass-blue-d;
$color-primary-e: $color-encompass-blue-e;
$color-primary-f: $color-encompass-blue-f;

$color-attention-orange: #F78E1E;
$color-error-red: #C0392B;
$color-succes-green: #27AE60;