@import "base";

.card {
  position: relative;
  border-radius: 3px;
  border: 1px solid $color-neutral-b;
}

.card__header {
  display: flex;
  height: 5.6rem;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid $color-neutral-b;
}

.card__header h2 {
  margin-top: 0;
}

.card__footer {
  display: flex;
  align-items: center;
  height: 5.6rem;
  padding: 0 4rem;
  border-top: 1px solid $color-neutral-b;
}