@import "base";

.ReactModal__Overlay {
  opacity: 0;
  transform: translateY(100px);
  transition: all 200ms;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
  transform: translateY(0);
}

.ReactModal__Overlay--before-close{
  opacity: 0;
  transform: translateY(100px);
}

.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: white;
  overflow: auto;
  &:focus { outline: none; }
}

.modal__overlay .ReactModal__Content {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: auto;

}

.modal__overlay--content {
  padding: 8rem 0;
}

.modal__overlay .close.icon {
  position: fixed;
  right: 4rem;
  top: 4rem;
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  border: .1rem solid $color-neutral-c;
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, 0.2);
  background: white;
  cursor: pointer;
  z-index: 3;

  &:before, &:after {
    content: '';
    display: block;
    position: absolute;
    width: .2rem;
    height: 2.4rem;
    background: black;
    top: 1.8rem;
    left: 2.8rem;

    transform: rotate(-45deg);
    transition: all .2s;
  }

  &:after {
    transform: rotate(45deg);
  }

  span {
    position: absolute;
    width: 100%;
    text-align: center;
    bottom: 0;
    opacity: 0;
    color: $color-neutral-a;

    transition: all .2s;
  }
}

.modal__overlay .close.icon:hover {

  &:before, &:after {
    top: 1.2rem;
  }

  span {
    opacity: 1;
    bottom: 8px;
  }
}


.react-confirm-alert-overlay {
  background: rgba(9, 14, 19, 0.9);
  animation-duration: .2s;
}

.modal__alert p {line-height: 1.3 };

.modal__confirm {
  width: 60rem;
  padding: 4rem;
  background: white;
  border-radius: 3px;

}

.modal__confirm--message i {
  display: inline-block;
  background: $color-neutral-8;
  color: white;
  padding: 2px 5px;
  border-radius: 11px;
  min-width: 22px;
  text-align: center;
  font-style: normal;
}