
/*

  Usage:
  <div class="mt-4 pl-2">content</div>
  this will add 4rem of margin-top and 2rem of padding-left to the element
*/

// margin

@for $i from 1 to 6 {
  $mt: ($i);
  .mt-#{$i} {
    margin-top: $i+rem;
  }
}

@for $i from 1 to 6 {
  $mb: ($i);
  .mb-#{$i} {
    margin-bottom: $i+rem;
  }
}

@for $i from 1 to 6 {
  $ml: ($i);
  .ml-#{$i} {
    margin-left: $i+rem;
  }
}

@for $i from 1 to 6 {
  $mr: ($i);
  .mr-#{$i} {
    margin-right: $i+rem;
  }
}

@for $i from 1 to 6 {
  $my: ($i);
  .my-#{$i} {
    margin: $i+rem 0;
  }
}

@for $i from 1 to 6 {
  $mx: ($i);
  .mx-#{$i} {
    margin: 0 $i+rem;
  }
}

// padding

@for $i from 1 to 6 {
  $pt: ($i);
  .pt-#{$i} {
    padding-top: $i+rem;
  }
}

@for $i from 1 to 6 {
  $pb: ($i);
  .pb-#{$i} {
    padding-bottom: $i+rem;
  }
}

@for $i from 1 to 6 {
  $pl: ($i);
  .pl-#{$i} {
    padding-left: $i+rem;
  }
}

@for $i from 1 to 6 {
  $pr: ($i);
  .pr-#{$i} {
    padding-right: $i+rem;
  }
}

@for $i from 1 to 6 {
  $py: ($i);
  .py-#{$i} {
    padding: $i+rem 0;
  }
}

@for $i from 1 to 6 {
  $px: ($i);
  .px-#{$i} {
    padding: 0 $i+rem;
  }
}

.pull-right {
  margin-left: auto;
}

.pull-left {
  margin-right: auto;
}

/* base-24 grid system */

$grid-columns: 24;
$grid-unit-width: (100% / $grid-columns);

@for $i from 1 to $grid-columns {
  .grid-#{$i} {
    width: ($grid-unit-width * $i);
  }
}

$flex-grow: 10;
$grow-unit-amount: (1 / $flex-grow);

@for $i from 1 to $flex-grow {
  .flex-grow-#{$i} {
    flex-grow: ($grow-unit-amount * $i) !important;
  }
}

