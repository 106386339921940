@import "../../styles/base";

.button {
  position: relative;
  min-width: 10rem;
  height: 3.4rem;
  color: black;
  border-radius: .3rem;
  border: .1rem solid $color-neutral-b;
  box-shadow: 0 1px 3px rgba(0,0,0,.2);
  outline: none;
  padding: 0 1.3rem;
  background: white;
  font-size: $font-size;
  cursor: pointer;

  transition: all .2s;

  &:active {
    color: $color-neutral-7;
    box-shadow: 0 1px 3px transparent;
    background: $color-neutral-f;
  }
}

.button--loading {
  color: transparent;
  background: $color-neutral-f;
  box-shadow: none;
  pointer-events: none;
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 16px;
    height: 16px;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    border: 2px solid $color-neutral-4;
    border-right-color: $color-neutral-c;
    border-top-color: $color-neutral-c;
    animation: button-loader .8s linear infinite;
  }

  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 8px;
    height: 14px;
    border-right: 2px solid $color-neutral-4;
    border-bottom: 2px solid $color-neutral-4;
    opacity: 0;
    transform: translate(-50%, 0);
    transition: none;
  }
}

.button.button__primary {
  background: $color-primary-6;
  color: white;
  border-color: $color-primary-5;

  transition: all .2s;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(hsla(0,0%,100%,.14),transparent);

    transition: all .2s;
  }

  &:active:after {
    opacity: 0;
  }

  &:active {
    box-shadow: 0 1px 3px transparent;
    background: $color-primary-7;
    color: $color-primary-e;
  }
}

.button.button__primary.button--loading {
  color: transparent;
  &:before {
    border-color: $color-primary-9;
    border-right-color: $color-primary-f;
    border-top-color: $color-primary-f;
    animation: button-loader .8s linear infinite;
  }

  &:after {
    border-right-color: 2px solid $color-primary-9;
    border-bottom-color: 2px solid $color-primary-9;
  }
}

.button-box {
  font-size: 0;
  .button + .button { margin-left: $s-size; }
}

@keyframes button-loader {
  0% {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(405deg);
  }
}