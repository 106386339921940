@import "base";


.context {
  background: $color-primary-e;
  border: 1px solid $color-primary-a;
  padding: 1rem 3rem;
  border-radius: 1rem;
  margin-left: -3rem;
  margin-bottom: 3rem;
  width: 100%;
  box-sizing: content-box;

  .entity {
    border: none;
    padding: 0;
  }
}

h1 + .context {
  margin-top: 4rem;
}