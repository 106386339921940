@import "../../styles/base";

.ReactModal__Overlay.modal__overlay--alert {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  background-color: rgba(9, 14, 19, 0.9);
  opacity: 0;
  transition: all .3s;
  transform: none;
}

.ReactModal__Overlay--after-open.modal__overlay--alert {
  opacity: 1;
}

.ReactModal__Overlay--before-close.modal__overlay--alert {
  opacity: 0;
}

.ReactModal__Content.modal__alert {
  width: 60rem;
  min-height: 20rem;
  background: white;
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 4rem;
  border-radius: 3px;

  transition: all .3s;

  &:focus {
    outline: none;
  }
}

.ReactModal__Content.modal__alert.ReactModal__Content--after-open {
  top: 50%;
}

.ReactModal__Content.modal__alert.ReactModal__Overlay--before-close {
  top: 70%
}

.modal__overlay .ReactModal__Content.modal__alert {
  width: 60rem;
  height: auto;
}

.modal__alert label { margin-top: 2rem; }
