@import "base";

.entity {
  display: flex;
  min-width: 34rem;
  border: 1px solid $color-neutral-c;
  padding: 0 1.3rem;
  height: 4.6rem;
  align-items: center;
  border-radius: 3px;
}

.entity .entity-icon {
  width: 30px;
  height: 30px;
  margin-right: 10px;

  svg {
    width: 30px;
    height: 30px;
  }
}

.entity .entity-name {
  color: $color-neutral-6;
}

.entity .entity-username {
  color: #000;
}