@import "base";

.Toastify__toast-container {}

.Toastify__toast {}

.Toastify__toast-body {}

.Toastify__toast.success {}
.Toastify__toast.success .icon {}
.Toastify__toast.success .message {}

.Toastify__toast.error {}
.Toastify__toast.error .icon {}
.Toastify__toast.error .message {}

.Toastify__close-button {}

.Toastify__toast {
  width: 40rem !important;
  background: white;
  box-shadow: 0 2px 4px rgba(0,0,0,.2);
  border-radius: 3px;
  border: 1px solid $color-neutral-b;
  overflow: hidden;
  padding: 0;
  font-size: $font-size;
  font-family: 'Open Sans', sans-serif;

  .Toastify__toast-body {
    display: inline-block;
    flex-grow: 1;
    flex-basis: 0;
    padding: 20px 15px 20px 20px;
    vertical-align: top;
    transition: all .3s;
    background: white;

    .status-message {
      padding-right: 60px;
    }
    label {
      display: block;
      max-width: calc(100% - 60px);
    }
  }
  .Toastify__close-button {
    position: absolute;
    flex-grow: 1;
    flex-basis: 0;
    max-width: 60px;
    width: 60px;
    min-width: initial;
    height: 100%;
    border: none;
    cursor: pointer;
    transition: all .2s;
    padding: 0;
    right: 0;
    top: 0;
    border-radius: 0;
    box-shadow: none;
    margin-top: 0;
    font-size: 0;
    opacity: 1;

    &:before, &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 12px;
      height: 2px;
      background: $color-neutral-b;
      transform: translate(-50%, -50%) rotate(45deg);
      transition: all .2s;
    }
    &:after { transform: translate(-50%, -50%) rotate(-45deg); }

    &:hover {
      background: $color-neutral-f;
      &:before, &:after {
        background: $color-neutral-6;
      }
    }

  }
}

// Component variations

.notification.action .notification-content {
  cursor: pointer;
  &:hover {
    background: $color-neutral-f;
  }
}

