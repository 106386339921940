@import "base";

.checkbox {
  position: relative;
  input.hidden {
    visibility: hidden;
    position: absolute;
  }
  label {
    cursor: pointer;
    line-height: 2.2rem;
    padding-left: 3.4rem;
  }
  label:before {
    content: '';
    position: absolute;
    display: block;
    width: 2.0rem;
    height: 2.0rem;
    border-radius: .3rem;
    border: 1px solid $color-neutral-c;
    background: white;
    top: 0;
    left: 0;
    box-shadow: 0 .1rem .2rem rgba(0,0,0,.1);
  }
  label:after {
    content: '';
    position: absolute;
    display: block;
    top: 7px;
    left: 5px;
    width: 1rem;
    height: .4rem;
    border: .2rem solid white;
    border-top: none;
    border-right: none;
    transform: rotate(-45deg);
    box-shadow: none;
  }

  &.disabled {
    label {cursor: default !important;}

    label:before {
      background: $color-primary-d !important;
      border-color: $color-primary-d !important;
      box-shadow: none;
    }
  }
}

.checkbox.checked label:before {
  background: linear-gradient($color-primary-7, $color-primary-6);
  border-color: $color-primary-5;
}