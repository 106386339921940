@import "settings";
@import "variables";

@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700|Raleway:600&display=swap');


html {
  font-size: 62.5%;
}

body {
  font-family: 'Open Sans', sans-serif;
  font-size: $font-size;
}

* { box-sizing: border-box; }

h1, h2, h3  {
  font-family: 'Raleway', sans-serif;
  margin: 0;
}

h1 + h2 { margin-top: 4rem; }

.text-center {
  text-align: center;
}



.content {
  position: fixed;
  overflow: auto;
  height: calc(100vh - 5.6rem);
  width: calc(100vw - 22rem);
  right: 0;
  overflow-x: hidden;
}

.content {
  &:before {
    content: '';
    position: fixed;
    display: block;
    top: 56px;
    left: 220px;
    width: calc(100% - 220px);
    height: 10px;
    background: linear-gradient(rgba(0, 0, 0, 0.2) -1px, transparent 6px);
    z-index: 1;
  }
}

