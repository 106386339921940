@import "../../styles/base";

.header {
  display: flex;
  align-items: center;
  height: 5.6rem;
}

.header__item {
  width: 22rem;
  padding-left: 2.6rem;
}

.header__logo {
  width: 16rem;
}

.header__item.header__item--title {
  width: calc(100% - 22rem);
  padding-left: 4rem;
}

.header .entity {
  position: absolute;
  right: 40px;
  top: 5px;
}